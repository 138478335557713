import React from "react"
import Layout from "../../Layout"
import { useStaticQuery, graphql } from "gatsby"
import { Link as GatsbyLink } from "gatsby"
import { 
    BlogPost,
    Content as Content,
    BlogSectionThreeColumns as BlogSection,
    StatsCard as Stats,
    LogoCloudSimpleBranded as LogoCloud,
    modelFactory
} from "@domenicosolazzo/site-designsystem"
import { blogItemParser, blogSectionParser } from "../../../../utils/blog/blogParser"
import { LogoCloudDataBlog } from "../../../../data"
import SEO from "../../../SEO/SEO"


export default function(props) {
    const data = useStaticQuery(graphql`
        query {
          allMdx(sort: {order: DESC, fields: frontmatter___date}) {
            edges {
              node {
                excerpt
                fileAbsolutePath
                frontmatter {
                  topic
                  title
                  tags
                  path
                  datePublished
                  authorName
                  date
                  description
                  draft
                  coverImageAlt
                  coverImage
                }
                id
                slug
                timeToRead
                wordCount {
                  words
                  sentences
                  paragraphs
                }
                body
              }
            }
            max(field: timeToRead)
            min(field: timeToRead)
            totalCount
            pageInfo {
              currentPage
              hasNextPage
              hasPreviousPage
              itemCount
              pageCount
              perPage
              totalCount
            }
          }
        }
    `)
    const logoCloud = LogoCloudDataBlog
    const frontmatter = props?.pageContext?.frontmatter
    const blogItem = blogItemParser({frontmatter,})
    const blogSection = blogSectionParser(data, {
        filter: {
          type: "random",
          elements: 3
        }
      })

    const SEOData = {
      title:frontmatter.title,
      description:frontmatter.description,
      image:frontmatter.coverImage,
      pathname:frontmatter.path,
      article:true,
    }
    return (
        <Layout>
            <SEO {...SEOData} />
            <Content {...blogItem}>
                {props.children}    
            </Content>   
            <BlogSection {...blogSection}/>   
            <LogoCloud {...logoCloud}/> 
        </Layout>
    )
}