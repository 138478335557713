import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In this episode, we are interviewing Kirill Soloviev, founder of Nimi and how they built their startup with no coding skills.`}</p>
    <p>{`Nimi is a startup that helps people who are choosing a name for their company, product, or brand.
It helps to stay away from poor name choices and avoid hurting their business.`}</p>
    <p>{`Kirill will share some useful insights that could help non-technical founders: how to build your MVP without technical people and not to obsess with finding one.`}</p>
    <p>{`There are many solutions which don’t need any knowledge about coding. They work out-of-the-box and you need to learn how to apply them to solve the customer problem.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/1*hBp9i_LZHGwKfq7plvjWxQ.jpeg",
        "alt": "Image"
      }}></img></p>
    <p>{`You will end up focusing more on your customer without waiting for the engineering team.
You will be able to communicate and inform the development team in a much better way when it is time to start coding.`}</p>
    <p>{`Let’s deep dive in the interview.`}</p>
    <h1>{`From idea to execution`}</h1>
    <h2>{`Tell us about yourself.`}</h2>
    <p>{`My name is Kirill Soloviev and I am the founder of Nimi.
It is a startup that help you analyze your brand name for global fit.`}</p>
    <p>{`I have a background software engineering even if that was more than 10 years ago.
So, I am not an engineer by any means in the recent years. I am aware what engineers can do but I wrote my last line of code 10 years ago.`}</p>
    <p>{`Even if you have a background in engineering, it is not always so easy to talk with developers.
I still fail to do a good job to communicate the business part of the startup.
It is difficult to do, that’s how life is. I will explain how it worked for me.`}</p>
    <h2>{`How did you start?`}</h2>
    <p>{`I started working on this idea for quite a long time (2015).
It actually started when a startup was contacting me about a branding problem. They were a Russian company and wanted to expand to new markets like UK.`}</p>
    <p>{`They were unsure if the product name they’ve chosen was the right fit for that UK market.`}</p>
    <p>{`After few weeks, a VP of marketing of another startup contacted me for the same exact issue. At that point, I almost made up my mind.`}</p>
    <p>{`Two unconnected startups contacting me with the exact same problem. Lots of challenges for solving this problem but a clear opportunity.`}</p>
    <p>{`This was the start for Nimi.`}</p>
    <h1>{`Moving to Estonia and the Hackathon`}</h1>
    <p>{`My wife and I have been moving to Estonia for 3–4 months. Exactly, in these months a 48-hours Hackathon was going to take place in their city.`}</p>
    <p>{`There were 20 teams for a total of 150 people.`}</p>
    <p>{`It was the perfect space for developing my idea from scratch in very short time and see if people would like it.`}</p>
    <p>{`I pitched the idea and I’ve got a team that would work with me during the weekend. The team included 6 people: 3 engineers, 1 product manager (Kirill), 1 salesman and 1 person for UX / Design.`}</p>
    <p>{`I had the pressure of showing his idea was valuable. It was a good motivator that helped the idea off the ground. On Sunday, we had our final deadline: demo day. All the teams would present their final accomplishments.`}</p>
    <blockquote>
      <b>VALUE BOX</b>
      <p parentName="blockquote">{`Looking for similar events around the world?`}</p>
      <p parentName="blockquote">{`Check your next Hackathon with `}<a parentName="p" {...{
          "href": "http://startupweekend.org/"
        }}>{`Startup Weekend`}</a></p>
      <p parentName="blockquote">{`Check the next Hackathon with `}<a parentName="p" {...{
          "href": "http://www.hackathon.io/"
        }}>{`Hackathon.IO`}</a></p>
    </blockquote>
    <h1>{`MVP`}</h1>
    <p>{`We had talked to all the teams and we needed a plan for succeeding on the final day.
The jury wanted to see from all the teams a real working product.`}</p>
    <p>{`We decided to split our team in two: the engineering team and the product team.
The engineering team would continue building the Nimi Platform. The product team would focus on delivering immediate value to the other teams.`}</p>
    <p>{`The engineering team used the first day for building the backbone of the real product. Yet, It was only the backend and they still had no screens that could show to us after one entire day.
Meanwhile, the product team was talking to the other teams.`}</p>
    <p>{`We were trying to understand how Nimi team could help them even before writing a single line of code.
Many people actually experienced a sort of problem when choosing a name for their teams.`}</p>
    <p>{`We started immediately bringing value to the teams using already available online services.`}</p>
    <h1>{`Google Forms`}</h1>
    <p>{`We used something called Google Forms for creating surveys.
We get useful insights from our customers and users. It is free and part of the Google product suite.`}</p>
    <p>{`Google Forms also collect the responses and visualize the results in a very nice manner.`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/xEY10Ub-k-U" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Google Forms</figcaption>
    </figure>
    <blockquote>
      <b>VALUE BOX</b>
      <p parentName="blockquote">{`Are you not enjoying making a form with Google Forms? Here 5 alternatives that could be useful:`}</p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.wufoo.com/"
        }}>{`Wufoo`}</a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.typeform.com/"
        }}>{`TypeForm`}</a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.limesurvey.org/"
        }}>{`LimeSurvey`}</a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.jotform.com/"
        }}>{`JotForm`}</a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://ninjaforms.com/"
        }}>{`Ninjia Forms`}</a></p>
    </blockquote>
    <h3>{`Messaging apps`}</h3>
    <p>{`We designed a simple survey and we were using any messaging apps for sharing it. We shared it with people that could give us good insights about a given brand name in their country.`}</p>
    <p>{`We had our minimum viable product (MVP) for learning how people reacted when faced to a business name.
It didn’t cost anything and required zero lines of code to operate.`}</p>
    <h3>{`Lesson`}</h3>
    <p>{`Don’t wait the engineering team for the perfect platform but start immediately bringing value to the customers. Plus, use the tools already available for building your MVP.`}</p>
    <h1>{`Demo day`}</h1>
    <p>{`Sunday arrived and demo day would start around 4:30 P.M.
We were 4th in the presentation list.`}</p>
    <p>{`One small problem: it was already 4 P.M. and the engineering team was not done yet.
The engineering guys still did not have anything to show of what they have built. They built some of the components like gathering data and placing an order in the system.
Yet, one component was missing: showing the results from the surveys. It was an important part of our system because it would show the real value of the product.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Don’t wait the engineering team for the perfect platform but start immediately bringing value to the customers.
Plus, use the tools already available for building your MVP.`}</p>
    </blockquote>
    <p>{`It was a clear failure in communication between the product team and the engineering team.`}</p>
    <p>{`As you can imagine, I was pretty frustrated. The most important component was not there. It was important for showing why we built this startup in the first place.`}</p>
    <p>{`Well, we went with plan B: fake it until you make it.
We took the results that were stored from Google Forms and screenshot the report page.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/5778/1*NhBpbElhamqjnK31sfdAYA.jpeg",
        "alt": "Room"
      }}></img></p>
    <p>{`We put this image in the missing report view built from the engineering team. The product was only 60% ready but it could actually show how the solution would work.`}</p>
    <p>{`The jury could see that we actually built and delivered real value to our customers. Even if the most important part of the software did not work, we managed to find a workaround.
We satisfied all the requirements from the hackathon and show some end results.`}</p>
    <h1>{`After the hackathon`}</h1>
    <p>{`After the hackathon, I talked to the team. The engineering team was not going to continue working on this idea.
They would not focus on my idea over their ideas or jobs. If I was relying on their code, it was going to be bad for my startup.`}</p>
    <p>{`I would have a bunch of code, and zero people that actually know how it works. Very little possibility of building it further.`}</p>
    <p>{`During the hackathon, you have very little time and engineers do not have much time to do things in the right way.
In short, it means that you will often end up with crappy code.`}</p>
    <p>{`At the end, I throw it away!`}</p>
    <h1>{`Zero code mentality`}</h1>
    <p>{`You should switch to a zero code mentality: use the tools available and you can go pretty far with that.`}</p>
    <p>{`We still work with many of these available tools. We use them in a very manual way. At the moment, we focus more on having a better understanding of the actual customer’s problems.
`}<img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/1*AEhlgdx8_AClQUBzGA58jw.jpeg",
        "alt": "Image"
      }}></img></p>
    <p>{`Our need for building a custom technology is still far far away in the future.
Software development is a huge and intensive job. Code can break. People make mistakes all the time. There are always delays on deadlines because of the software part being not ready.`}</p>
    <p>{`It can be a huge pain from the business perspective of a former technical guy.
A startup should be much leaner if they can afford to go without customer code for as long as possible.`}</p>
    <blockquote>
      <b>VALUE BOX</b>
      <p parentName="blockquote">{`Interesting online services that do not need any coding skills`}</p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "http://bit.ly/2twOaQy"
        }}>{`Zero Coding`}</a></p>
    </blockquote>
    <h2>{`What are your takeaways from this experience?`}</h2>
    <p>{`Some key takeaways:`}</p>
    <ul>
      <li parentName="ul">{`Don’t obsess with the solution. Obsess with the customer’s point of view of the problem.`}</li>
      <li parentName="ul">{`How far can you go in a pure manual way delivering value to the customer, before writing a single line of code.`}</li>
    </ul>
    <p>{`This is a mentality that startups could benefit from.`}</p>
    <p>{`People obsess with “omg, I don’t know how to code”, “ I need to find an engineering partner”.
There are many solutions that don’t need you to know how to code.`}</p>
    <p>{`They work out-of-the-box. Learn how to apply these tools to solve your customer’s problem.`}</p>
    <b>
Being a technical founder, you don’t have to build everything from scratch. It is about reducing the amount of code that you write. 
Engineers love to code and they would always be ready to open their editor and start coding. 
But, you hire them to develop solutions and not being code monkeys! You hire them to bring value to the company.</b>
    <p>{`Exactly, it is actually a good point. Unfortunately, not every engineer would agree with that. Less code, being efficient and understanding that writing code is not the only way.`}</p>
    <h2>{`Current Stack`}</h2>
    <b>What is your current tech stack?</b>
We use several tools in our current stack like Tilda, Typeform, Paypal, Zapier and much more.
    <h3>{`Tilda`}</h3>
    <p>{`The website is the first touch point for our customer. I built it using `}<a parentName="p" {...{
        "href": "https://tilda.cc/"
      }}>{`Tilda`}</a>{`.
This tool helps you to build a website with zero lines of code involved in a very short amount of time. It took us only a couple of hours to build the landing page.`}</p>
    <p>{`It also did not cost anything for few weeks. We updated to the paid plan because we needed to attach our domain.`}</p>
    <p>{`It integrates very well with other tools that we are using in our current stack like `}<a parentName="p" {...{
        "href": "https://www.typeform.com/"
      }}>{`Typeform`}</a>{` and `}<a parentName="p" {...{
        "href": "http://paypal.com/"
      }}>{`Paypal`}</a>{`.`}</p>
    <blockquote>
      <b>VALUE BOX</b>
      <p parentName="blockquote">{`There are many alternatives to Tilda for building your site.
Here’s the list:`}</p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.wix.com/"
        }}>{`Wix`}</a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.squarespace.com/"
        }}>{`Squarespace`}</a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.weebly.com/"
        }}>{`Weebly`}</a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "http://www.imcreator.com/"
        }}>{`IM Creator`}</a></p>
    </blockquote>
    <h2>{`Typeform`}</h2>
    <p>{`We use `}<a parentName="p" {...{
        "href": "https://www.typeform.com/"
      }}>{`Typeform`}</a>{` to actually gather the intelligence from our customers.
I could not recommend enough. It is a beautiful way to capture any kind of information from the users.`}</p>
    <p>{`It turns something boring like surveys into something a bit more exciting.`}</p>
    <p>{`For us, a survey is something that we use to gather intelligence. Typeform gives us a better completion rate with respect to Google Forms.
Customers and users love them.
We are using a Basic plan and paying very little for it.`}</p>
    <h2>{`Infogram`}</h2>
    <p>{`For the visualization tool, we use a tool called `}<a parentName="p" {...{
        "href": "https://infogram.com/"
      }}>{`Infogram`}</a>{`.
It is an online tool for infographics and it is very good for building visual reports.`}</p>
    <p>{`It helps you to gather data from some external services (e.g. Typeform). Export them to an excel file and importing it to Infogram.`}</p>
    <p>{`Infogram updates data in real time. If you want to change any of your data, change them and your customer can refresh the report in real time.
Very useful and much better than sending many pdf around. It is a real time saver.
Any modification to the layout and the data will be updated automatically; we do not need to email again the customer.`}</p>
    <p>{`Typeform and Infogram are the core of our application.`}</p>
    <h2>{`PayPal`}</h2>
    <p>{`How do we get paid? We use `}<a parentName="p" {...{
        "href": "https://www.paypal.com/"
      }}>{`PayPal`}</a>{`!`}</p>
    <p>{`We send the customer a link from PayPal. You can define in the link itself how much money the customer has to pay (e.g. 50euro, 200 euro, …) and that’s it.`}</p>
    <p>{`No code, we needed a PayPal account and with few clicks we got our payment in our account.`}</p>
    <h2>{`Zapier`}</h2>
    <p>{`Another tool that we are using is Zapier. It is very useful.
For not technical guys, it is like glue between different applications online.
We are using Zapier for automating notifications. For example, when a customer place an order`}</p>
    <p>{`Zapier talks to Typeform, and if anything happens in there it will push a message to our `}<a parentName="p" {...{
        "href": "https://slack.com/"
      }}>{`Slack`}</a>{` channel.`}</p>
    <h2>{`Slack`}</h2>
    <p><a parentName="p" {...{
        "href": "https://slack.com/"
      }}>{`Slack`}</a>{` is our information hub! We do all our communication between team members there.`}</p>
    <p>{`These are the tools that we are using today.`}</p>
    <h1>{`Workflow`}</h1>
    <p>{`This is our flow for getting a payment from our customer. The customer arrives at the website (Tilda) and fills the integrated form (Typeform).
They enter their details:
– the country that they want to launch
– email
– other general information about the customer.`}</p>
    <p>{`When the customer submits the form, we get a notification through Zapier on our Slack channel.`}</p>
    <p>{`At the same time, we will send a link to the user for the PayPal payment. The user will add their credit card information and we will get the money.`}</p>
    <p>{`Afterward, we use the email to contact the customer back.`}</p>
    <p>{`What is important for us is to be able to shut down a tool and use any other tool at any time.`}</p>
    <h1>{`Conclusion`}</h1>
    <p>{`The most important part is that we found a real problem and how to solve it with little technology involved. We were immediately able to deliver value to our customer.`}</p>
    <p>{`Start with a problem first. When you start designing a solution, try to think in a way that actually needs zero coding.`}</p>
    <ul>
      <li parentName="ul">{`Can you be the one pulling all the strings behind the scene?`}</li>
      <li parentName="ul">{`Can you be the one answering the phone or typing in a chat window?`}</li>
      <li parentName="ul">{`Can you build a solution that works for the customer without writing any custom code?`}</li>
    </ul>
    <p>{`The challenge with code is that it is hard to produce. It needs high qualified people, it is hard to maintain and it is difficult to change.`}</p>
    <p>{`At the beginning of a startup you have many things that are not defined. You might not know the complete customer problem. Or, how much profit the startup could make, etc…`}</p>
    <blockquote>
      <p parentName="blockquote">{`Start with a problem first.
When you start designing a solution, try to think in a way that actually needs zero coding.`}</p>
    </blockquote>
    <p>{`Trying to write code at this stage could be a very bad idea. There is a chance that you will throw it away in few weeks or months because you will discover that you did it wrong.`}</p>
    <p>{`Then, you will need to start from scratch. Our code did not work but we found a workaround to that.
What I am trying to say is try to do as much as you can before starting building custom software.`}</p>
    <p>{`Even a technical founder would benefit a lot from this mindset in the beginning.`}</p>
    <blockquote>
      <b>VALUE BOX</b>
      <p parentName="blockquote">{`Want to know more about Nimi?`}</p>
      <p parentName="blockquote">{`Check their site at `}<a parentName="p" {...{
          "href": "http://nimi.io/"
        }}>{`nimi.io`}</a></p>
    </blockquote>
    <h4>{`AUTHOR`}</h4>
    <p>{`Domenico is a software developer with a passion for design, psychology, and leadership.
If you need help with software development, you need consultancy for your technical challenges or you need a leader for your software team or just want to collaborate online, feel free to contact me!`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      